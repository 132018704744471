import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Newsletter from "../components/Newsletter";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
//import SEO from "../components/seo"

const Text = ({ children }) => (
  <h2 className="text-2xl md:text-3xl lg:text-3xl xl:text-4xl font-semibold text-gray-600 pb-3">
    {children}
  </h2>
);

const Paragraph = ({ children }) => (
  <p className="text-base md:text-xl lg:text-xl xl:text-xl pb-10 md:pb-14">
    {children}
  </p>
);

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
  },
};

const AboutUsPage = ({ data }) => {
  const { title, content } = data.contentfulAboutUs;
  return (
    <Layout>
      <div className="pt-24 md:pt-32 lg:pt-32 xl:pt-32">
        <Link className="" to="/">
          <div className="flex items-center mx-5 md:mx-10 lg:mx-12 xl:mx-20 mb-16 lg:mb-24 xl:mb-32 pt-12 border-b-2 lg:border-b-4 pb-5 lg:pb-10 border-amber-400">
            <svg
              class="w-7 h-7 lg:w-10 lg:h-10"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <h1 className="pl-6 lg:pl-10 text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold">
              About Us
            </h1>
          </div>
        </Link>
      </div>
      <div className="max-w-screen-lg xl:mx-auto mx-5 md:mx-10 lg:mx-32">
        <h1 className="text-4xl md:text-5xl lg:text-5xl xl:text-6xl font-semibold pb-10 md:pb-14">
          {title}
        </h1>

        {content && renderRichText(content, options)}
      </div>
      <Newsletter />
    </Layout>
  );
};

export const query = graphql`
  {
    contentfulAboutUs {
      id
      title
      content {
        raw
      }
    }
  }
`;

export default AboutUsPage;
